import React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import gql from 'graphql-tag';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import GlobalContext from '../../../GlobalContext';

import {
  useTheme,
  makeStyles,
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  Checkbox,
  MenuItem,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import useOnboarding from './useOnboarding';

import WorkflowBar from './WorkflowBar';

import DriverLocationSelect from './DriverLocationSelect';

const log = false;

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function FormAddressInfo({ driver = {}, flowGroup, flowSection, onClose, refetch }) {
  const theme = useTheme();
  const ctx = React.useContext(GlobalContext);
  const cls = useStyles();

  // Hook functions
  const { proceedToPrevWorkflow, proceedToNextWorkflow } = useOnboarding();

  // General form state
  const [editMode, setEditMode] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [validation, setValidation] = React.useState({});

  // Value form State
  const [homeLocation, setHomeLocation] = React.useState(null);
  const [mailLocation, setMailLocation] = React.useState(null);
  const [matchAddressFlag, setMatchAddressFlag] = React.useState(null);
  const [maxDriveRadius, setMaxDriveRadius] = React.useState(0);
  const [maxDriveRadiusFlag, setMaxDriveRadiusFlag] = React.useState(null);

  // Update state with driver fields (when driver changes)
  React.useEffect(() => {
    log && console.log(`driver:`, driver);
    if (driver) {
      setHomeLocation(driver?.home_location || null);
      if (
        driver?.home_location &&
        driver?.mailing_location &&
        driver?.home_location?.address === driver?.mailing_location?.address
      ) {
        setMatchAddressFlag(true);
      } else if (driver?.mailing_location) {
        setMatchAddressFlag(false);
        setMailLocation(driver?.mailing_location || null);
      } else setMatchAddressFlag(null);

      if (driver?.max_drive_radius_mi) {
        setMaxDriveRadiusFlag(true);
        setMaxDriveRadius(driver?.max_drive_radius_mi);
      } else {
        setMaxDriveRadiusFlag(false);
        setMaxDriveRadius(0);
      }
    }
  }, [driver]);

  // Checks if all inputs are valid
  const handleValidateForm = () => {
    let valid = true;

    const newValidation = {
      homeLocation: homeLocation ? true : false,
      mailLocation: matchAddressFlag !== false || mailLocation ? true : false,
      matchAddress: matchAddressFlag === true || matchAddressFlag === false,
      maxDriveRadius: !maxDriveRadiusFlag || (maxDriveRadiusFlag && maxDriveRadius >= 0) ? true : false,
      maxDriveRadiusFlag: maxDriveRadiusFlag === true || maxDriveRadiusFlag === false,
    };
    setValidation(newValidation);

    Object.keys(newValidation).forEach((key, i) => {
      const value = Object.values(newValidation)[i];
      if (value === false) {
        valid = false;
        setIsSaving(false);
      }
    });

    return valid;
  };

  // Mutation handler
  const saveAddress = async (homeLocationId, mailLocationId, startingPoint) => {
    const variables = {
      driverId: driver.id,
      driverInput: {
        home_location_id: homeLocationId,
        mailing_location_id: mailLocationId,
        max_drive_radius_mi: maxDriveRadiusFlag ? maxDriveRadius : null,
        starting_location: startingPoint,
        updated_at: `now()`,
      },
      driverdetailInput: {
        address_city: homeLocation?.city || null,
        address_one: homeLocation?.address_line_one || null,
        address_state: homeLocation?.state || null,
        address_two: homeLocation?.address_line_two || null,
        address_zip: homeLocation?.zip_code || null,
        mail_address_city: matchAddressFlag ? homeLocation?.city : mailLocation?.city || null,
        mail_address_one: matchAddressFlag ? homeLocation?.address_line_one : mailLocation?.address_line_one || null,
        mail_address_state: matchAddressFlag ? homeLocation?.state : mailLocation?.state || null,
        mail_address_two: matchAddressFlag ? homeLocation?.address_line_two : mailLocation?.address_line_two || null,
        mail_address_zip: matchAddressFlag ? homeLocation?.zip_code : mailLocation?.zip_code || null,
        updated_at: `now()`,
      },
    };

    log && console.log(`saveAddress - Variables:`, variables);

    try {
      const res = await ctx.apolloClient.mutate({
        mutation: SAVE_ADDRESS,
        variables: variables,
      });

      if (getPropValue(res, `data.update_driverdetails.affected_rows`)) {
        if (refetch) refetch();
        return { success: true };
      }

      const errStr = `Failed to update information. Please contact support.`;
      console.error(errStr);
      return { success: false, error: errStr };
    } catch (err) {
      const errStr = `Failed to update information. Please contact support.`;
      console.error(`Error updating information:`, err);
      return { success: false, error: errStr };
    }
  };

  // Handle saving the form
  const handleSave = async (proceed = false) => {
    // Get the user token from the store
    const userToken = ctx.token;

    try {
      // Remove __typename and potentially ID from homeLocation and mailLocation
      const editedHomeLocation = { ...homeLocation, id: driver?.home_location?.id };
      const editedMailLocation = matchAddressFlag
        ? { ...homeLocation, id: driver?.mailing_location?.id }
        : { ...mailLocation };

      delete editedHomeLocation.__typename;
      delete editedMailLocation.__typename;

      if (editedHomeLocation.id === null) delete editedHomeLocation.id;
      if (editedMailLocation.id === null) delete editedMailLocation.id;

      // Save the locations
      let locationsOutput = null;

      const locationsRes = await axios({
        method: `POST`,
        url: `/.netlify/functions/saveLocations`,
        data: {
          locationsInput: [editedHomeLocation, editedMailLocation],
        },
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });

      // If the locations were saved successfully, set the home and mail location ids
      if (locationsRes?.data?.success) locationsOutput = locationsRes?.data?.locationsOutput;

      const homeLocationOutput = locationsOutput?.[0];
      const mailLocationOutput = locationsOutput?.[1];
      const startingPoint = driver?.starting_location
        ? driver?.starting_location
        : {
            type: 'Point',
            coordinates: [homeLocationOutput?.longitude, homeLocationOutput?.latitude],
          };

      // Save address/location information in the database
      const addressRes = await saveAddress(homeLocationOutput?.id, mailLocationOutput?.id, startingPoint);
      if (addressRes?.success === true) {
        setIsSaving(false);
        toast.success(`Information saved!`, { autoClose: 2500 });

        if (proceed) {
          proceedToNextWorkflow(flowGroup, flowSection.id, onClose);
          return;
        }
        if (onClose) {
          onClose();
          return;
        }
      }
    } catch (err) {
      console.error(`Failed to save location information:`, err);
      toast.error(`Failed to save location information. Please contact support.`);
    }
  };

  // List of button actions in the workflow bar
  const actions = [
    {
      label: `Save`,
      icon: `save`,
      color: `secondary`,
      tip: `Save changes & return to the LaunchPad.`,
      handler: async () => {
        setIsSaving(true);
        const valid = handleValidateForm();
        if (valid) await handleSave();
        else toast.error(`Failed to save. Please check the form for errors.`);
      },
      hide: !editMode,
    },
    {
      label: `Save & Proceed`,
      icon: `double_arrow`,
      color: `primary`,
      tip: `Save changes & proceed to the next section.`,
      handler: async () => {
        setIsSaving(true);
        const valid = handleValidateForm();
        if (valid) await handleSave(true);
        else toast.error(`Failed to save. Please check the form for errors.`);
      },
      hide: !editMode,
    },
    {
      label: `Back`,
      icon: `arrow_back`,
      variant: `outlined`,
      color: `secondary`,
      tip: `Jump to the previous section.`,
      handler: () => proceedToPrevWorkflow(flowGroup, flowSection.id, onClose),
      hide: editMode,
    },
    {
      label: `Forward`,
      icon: `arrow_forward`,
      variant: `outlined`,
      color: `primary`,
      tip: `Jump to the next section.`,
      handler: () => proceedToNextWorkflow(flowGroup, flowSection.id, onClose),
      hide: editMode,
    },
  ];

  return (
    <>
      <WorkflowBar
        groupTitle={flowGroup.label}
        sectionTitle={flowSection.label}
        actions={actions}
        isSaving={isSaving}
        onClose={onClose}
        // editMode={editMode}
        // setEditMode={setEditMode}
      />

      <div className={cls.workflowPad} />

      <Spacer size='xxl' />

      <Container maxWidth='md'>
        <div className={cls.workflow}>
          {/* HOME ADDRESS INFORMATION */}

          <Typography className={cls.title}>Home Address</Typography>

          <Typography className={cls.subtitle}>
            Start filling out your home address below, then select from the dropdown once you see it pop up in the list.
          </Typography>

          <Typography className={cls.subtitle}>
            <b>
              If your address is not popping up in the list, try providing your full address and hit "Enter/Return".
              Remember to include apartment/unit number if applicable. If you are still having issues, please double
              check the format/spelling. Otherwise, contact HopDrive support.
            </b>
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  Home Address<span style={{ color: theme.palette.error.main }}> *</span>
                </Typography>
              </div>

              <DriverLocationSelect
                disabled={!editMode}
                type='driver-home'
                placeholder='Find your home address...'
                defaultLocationId={driver?.home_location?.id}
                location={homeLocation}
                onLocationChange={setHomeLocation}
              />
            </Grid>
          </Grid>

          <Spacer size='xl' />

          {/* MAILING ADDRESS INFORMATION */}

          <Typography className={cls.title}>Mailing Address</Typography>

          <Typography className={cls.subtitle}>
            Please specify if your home address and mailing address are identical. If not, please fill out your mailing
            address below.
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  Are your home address and mailing address identical?
                  <span style={{ color: theme.palette.error.main }}> *</span>
                </Typography>
              </div>

              {validation.matchAddress === false ? (
                <Typography className={cls.errorMessage}>Please select an option below</Typography>
              ) : null}

              <div className={cls.checkDiv}>
                <Checkbox
                  disabled={!editMode}
                  className={cls.checkBox}
                  checked={matchAddressFlag === true}
                  onChange={() => setMatchAddressFlag(true)}
                  icon={<Icon>radio_button_unchecked</Icon>}
                  checkedIcon={<Icon>radio_button_checked</Icon>}
                />
                <Typography className={cls.checkTxt}>Yes, my home address and mailing address are the same.</Typography>
              </div>

              <div className={cls.checkDiv}>
                <Checkbox
                  disabled={!editMode}
                  className={cls.checkBox}
                  checked={matchAddressFlag === false}
                  onChange={() => setMatchAddressFlag(false)}
                  icon={<Icon>radio_button_unchecked</Icon>}
                  checkedIcon={<Icon>radio_button_checked</Icon>}
                />
                <Typography className={cls.checkTxt}>
                  No, my home address is different from my mailing address.
                </Typography>
              </div>
            </Grid>

            {matchAddressFlag === false ? (
              <>
                <Grid item xs={12}>
                  <div className={cls.label}>
                    <Typography className={cls.labelTxt}>
                      Mailing Address<span style={{ color: theme.palette.error.main }}> *</span>
                    </Typography>
                  </div>

                  <DriverLocationSelect
                    disabled={!editMode}
                    type='driver-mail'
                    placeholder='Find your mailing address...'
                    defaultLocationId={driver?.mailing_location?.id}
                    location={mailLocation}
                    onLocationChange={setMailLocation}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>

          <Spacer size='xl' />

          {/* MAXIMUM DRIVE RADIUS INFORMATION */}

          <Typography className={cls.title}>Maximum Drive Radius</Typography>

          <Typography className={cls.subtitle}>
            Normally, HopDrive will not ask you to leave your designated region to perform your job, but there are
            occasional exceptions to this. In addition, if you are in a particularly large region, you may need to
            specify the limit of which you are willing to drive from your daily starting point.
          </Typography>

          <Typography className={cls.subtitle}>
            <b>
              NOTE: This is a suggested maximum and you may still receive job offers that are outside of your specified
              radius. In general, the more restrictive you are, the less job offers you might receive.
            </b>
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  Would you like to limit the distance you are willing to drive from your daily starting point?
                  <span style={{ color: theme.palette.error.main }}> *</span>
                </Typography>
              </div>

              {validation.maxDriveRadiusFlag === false ? (
                <Typography className={cls.errorMessage}>Please select an option below</Typography>
              ) : null}

              <div className={cls.checkDiv}>
                <Checkbox
                  disabled={!editMode}
                  className={cls.checkBox}
                  checked={maxDriveRadiusFlag === true}
                  onChange={() => setMaxDriveRadiusFlag(true)}
                  icon={<Icon>radio_button_unchecked</Icon>}
                  checkedIcon={<Icon>radio_button_checked</Icon>}
                />
                <Typography className={cls.checkTxt}>Yes, I would like to limit my maximum drive radius.</Typography>
              </div>

              <div className={cls.checkDiv}>
                <Checkbox
                  disabled={!editMode}
                  className={cls.checkBox}
                  checked={maxDriveRadiusFlag === false}
                  onChange={() => setMaxDriveRadiusFlag(false)}
                  icon={<Icon>radio_button_unchecked</Icon>}
                  checkedIcon={<Icon>radio_button_checked</Icon>}
                />
                <Typography className={cls.checkTxt}>No, I do not want to limit my maximum drive radius.</Typography>
              </div>
            </Grid>

            {maxDriveRadiusFlag ? (
              <Grid item sm={6} xs={12}>
                <div className={cls.label}>
                  <Typography className={cls.labelTxt}>
                    Maximum Drive Radius<span style={{ color: theme.palette.error.main }}> *</span>
                  </Typography>
                </div>

                <TextField
                  select
                  disabled={!editMode}
                  required
                  fullWidth
                  placeholder='Enter maximum drive radius...'
                  variant='outlined'
                  size='small'
                  value={maxDriveRadius}
                  onChange={e => setMaxDriveRadius(e.target.value || 0)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment className={cls.adornmentOutlined} position='start'>
                        <Icon color='disabled' fontSize='small'>
                          gps_fixed
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                  error={validation.maxDriveRadius === false}
                  helperText={validation.maxDriveRadius === false ? `Maximum drive radius is invalid` : null}
                >
                  <MenuItem value={0}>No Limit</MenuItem>
                  <MenuItem value={10}>10 Miles</MenuItem>
                  <MenuItem value={20}>20 Miles</MenuItem>
                  <MenuItem value={30}>30 Miles</MenuItem>
                  <MenuItem value={40}>40 Miles</MenuItem>
                  <MenuItem value={50}>50 Miles</MenuItem>
                </TextField>
              </Grid>
            ) : null}
          </Grid>

          <Spacer size='xxl' />
        </div>
      </Container>

      <Spacer size='xl' />
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = makeStyles(theme => ({
  workflowPad: {
    width: '100%',
    height: 56,
  },

  workflow: {
    position: 'relative',
    width: '100%',
    // padding: theme.spacing(2),
    // borderRadius: theme.shape.paperRadius,
    // background: theme.palette.background.paper,
    // boxShadow: theme.shadow.medium,
  },

  title: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  subtitle: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1.25,
    fontSize: 14,
    // color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },

  label: {
    display: 'flex',
  },
  labelTxt: {
    verticalAlign: 'top',
    margin: '4px 0',
    lineHeight: 1,
    fontSize: 14,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      margin: '3px 0',
      fontSize: 12,
    },
  },
  labelHelp: {
    display: 'block',
    verticalAlign: 'top',
    marginLeft: 'auto',
    lineHeight: 1,
    fontSize: 20,
    color: theme.palette.text.disabled,
    '&:hover': {
      color: theme.palette.text.primary,
    },
    transition: '0.15s',
    cursor: 'pointer',
  },

  adornment: {
    verticalAlign: 'top',
    marginBottom: 6,
  },
  adornmentOutlined: {
    verticalAlign: 'top',
  },
  placeholder: {
    color: theme.palette.text.disabled,
  },

  errorMessage: {
    marginLeft: 14,
    lineHeight: 1.25,
    fontSize: 12,
    color: theme.palette.error.main,
  },

  checkDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  checkBox: {
    padding: theme.spacing(1),
  },
  checkTxt: {
    marginLeft: 4,
    lineHeight: 1.25,
    fontSize: 14,
  },
}));

//////////////////////////////////////// GRAPHQL ////////////////////////////////////////

const SAVE_ADDRESS = gql`
  mutation driver_saveAddress(
    $driverId: bigint!
    $driverInput: drivers_set_input!
    $driverdetailInput: driverdetails_set_input!
  ) {
    update_drivers(where: { id: { _eq: $driverId } }, _set: $driverInput) {
      affected_rows
      returning {
        id
        home_location_id
        mailing_location_id
        max_drive_radius_mi
        starting_location
        updated_at
      }
    }
    update_driverdetails(where: { driver_id: { _eq: $driverId } }, _set: $driverdetailInput) {
      affected_rows
      returning {
        driver_id
        address_city
        address_one
        address_state
        address_two
        address_zip
        mail_address_city
        mail_address_one
        mail_address_state
        mail_address_two
        mail_address_zip
        updated_at
      }
    }
  }
`;
