import React from 'react';
import { Loading } from '@hopdrive/storybook';
import GlobalContext from '../../../GlobalContext';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { useTools } from '../../../hooks/useTools';

import StaticAlert from '../../utils/StaticAlert';
import LaunchPad from './LaunchPad';

const log = false;

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function Onboarding(props) {
  const { driverId } = React.useContext(GlobalContext);
  const { spreadDriverObj } = useTools();

  const [onboardingDriverId, setOnboardingDriverId] = React.useState(null);

  // Force a state change
  React.useEffect(() => {
    if (driverId) setOnboardingDriverId(driverId);
  }, [driverId]);

  return (
    <>
      {onboardingDriverId ? (
        <Query query={GET_DRIVER} variables={{ driverId: onboardingDriverId }} fetchPolicy={`network-only`}>
          {({ loading, error, data, refetch }) => {
            // LOADING STATE //
            if (loading) {
              return <Loading position='fixed' />;
            }

            // ERROR STATE //
            if (error) {
              console.error('Failed to load driver info:', error);
              return <StaticAlert type='error' message='Failed to load driver info.' />;
            }

            // EMPTY STATE //
            if (!data || !data.drivers || !data.drivers.length) {
              return <StaticAlert type='info' message='No driver info available.' />;
            }

            // DATA STATE //
            const handleRefetch = () => refetch();

            const driver = spreadDriverObj(data.drivers[0]);
            log && console.log(`Driver:`, driver);
            return <LaunchPad driver={driver} refetch={handleRefetch} />;
          }}
        </Query>
      ) : (
        <Loading position='fixed' />
      )}
    </>
  );
}

//////////////////////////////////////// GRAPHQL ////////////////////////////////////////

const GET_DRIVER = gql`
  query get_driver($driverId: bigint!) {
    drivers(where: { id: { _eq: $driverId } }) {
      id
      bank_account_token
      config
      max_drive_radius_mi
      phone_type
      plate_id
      region_id
      starting_location
      status
      tax_class
      user_id
      vehicle_color
      vehicle_license_plate
      vehicle_license_plate_state
      vehicle_make
      vehicle_model
      vehicle_vin
      vehicle_year
      verification
      wallet_token
      updated_at
      driverdetail {
        address_city
        address_one
        address_state
        address_two
        address_zip
        date_of_birth
        emergency_name_one
        emergency_name_two
        emergency_phone_one
        emergency_phone_two
        emergency_relationship_one
        emergency_relationship_two
        employer_identifier
        employer_name
        first_name
        gender
        insurance_coverage_amount
        insurance_policy_id
        insurance_provider
        insurance_rideshare_rider
        last_name
        license_number
        license_photo_url
        license_state
        mail_address_city
        mail_address_one
        mail_address_state
        mail_address_two
        mail_address_zip
        middle_name
        primary_phone
        social_security
        veteran
      }
      home_location {
        id
        active
        address
        address_line_one
        address_line_two
        city
        latitude
        longitude
        name
        place_id
        region_id
        state
        timezone
        type
        zip_code
      }
      mailing_location {
        id
        active
        address
        address_line_one
        address_line_two
        city
        latitude
        longitude
        name
        place_id
        region_id
        state
        timezone
        type
        zip_code
      }
      user {
        id
        active
        avatar_url
        display_name
        email
        phone
        default_role
      }
    }
  }
`;
