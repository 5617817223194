import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

//////////////////////////////////////// HOOK ////////////////////////////////////////

export function useTools() {
  /** Breakdown a string of text to its simplest form (remove all formatting and spacing) */
  const condensedCase = (str = null) => {
    if (str && typeof str === `string`) {
      const newStr = str
        .replace(/[,.?!:;`'"/{}()<>\s_+-]/g, ``)
        .toLowerCase()
        .trim();
      return newStr;
    }
    return str;
  };

  /** Capitalize the first letter of the first word */
  const capFirst = (str = null) => {
    if (str && typeof str === `string`) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  };

  /** Capitalize the first letter of each word */
  const capEach = (str = null) => {
    if (str && typeof str === `string`) {
      if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
      else {
        let arr = str.split(` `);
        arr = arr.map(s => s.charAt(0).toUpperCase() + s.slice(1));
        return arr.join(` `);
      }
    }
    return str;
  };

  /** Capitalize every letter of every word */
  const capAll = (str = null) => {
    if (str && typeof str === `string`) {
      return str.toUpperCase();
    }
    return str;
  };

  /** Round a number with a precision */
  const round = (num = null, precision = 0) => {
    if (num) {
      const multiplier = Math.pow(10, precision);
      const output = Math.round(num * multiplier) / multiplier;
      return output;
    }
    return 0;
  };

  /** Detect if a number is negative */
  const checkNeg = num => {
    if (num > 0) return num;
    else return 0;
  };

  /** Get human readable name from rideshare partner */
  const getFormattedRidesharePartner = (str = null) => {
    if (str && typeof str === `string`) {
      const newStr = condensedCase(str);
      if (newStr === `lyft` || newStr === `l`) return `Lyft`;
      if (newStr === `uber` || newStr === `u`) return `Uber`;
      if (newStr === `auto` || newStr === `a`) return `Auto`;
      if (newStr === `hopdrive` || newStr === `h` || newStr === `hd`) return `HopDrive`;
    }
    return null;
  };

  /** Get formatted combined status string from a drive or ride */
  const getFormattedCombinedStatusFromMove = (move = null) => {
    // Default fallback status string when not enough info is provided
    let fallbackStatus = `Unknown`;

    // Check for move
    if (move) {
      // Set local variables
      fallbackStatus = move.move_type === `drive` ? `Not Assigned` : `Not Started`;
      const cancelStatus = move && move.cancel_status ? condensedCase(move.cancel_status) : null;
      const status = move && move.status ? condensedCase(move.status) : null;

      // Check statuses and return a formatted string
      if (cancelStatus) {
        if (cancelStatus === `pending`) return `Pending Cancel`;
        if (cancelStatus === `seen`) return `Cancel Was Seen`;
        if (cancelStatus === `canceled` || cancelStatus === `cancelled`) return `Cancel Before Started`;
        if (cancelStatus === `started`) return `Cancel After Started`;
        if (cancelStatus === `delivered`) return `Cancel After Delivered`;
        return capEach(move.cancel_status);
      }
      if (status) {
        if (status === `dispatched`) return `Dispatched`;
        if (status === `pickupstarted`) return `Pickup Started`;
        if (status === `pickuparrived`) return `Pickup Arrived`;
        if (status === `pickupsuccessful`) return `Pickup Successful`;
        if (status === `deliverystarted`) return `Delivery Started`;
        if (status === `deliveryarrived`) return `Delivery Arrived`;
        if (status === `deliverysuccessful`) return `Delivery Successful`;
        if (status === `awaitingresponse`) return `Awaiting Response`;
        if (status === `accepted`) return `Accepted`;
        if (status === `arrived`) return `Arrived`;
        if (status === `pickedup`) return `Picked Up`;
        if (status === `droppedoff`) return `Dropped Off`;
        if (status === `canceled` || status === `cancelled`) return `Canceled`;
        if (status === `failed`) return `Failed`;
        return capEach(move.status);
      }
    }
    return fallbackStatus;
  };

  /** Get formatted status string from a drive */
  const getFormattedStatusFromDrive = (drive = null) => {
    // Default fallback status string when not enough info is provided
    let fallbackStatus = `Unknown`;

    // Check for drive
    if (drive) {
      // Set local variables
      fallbackStatus = `Not Assigned`;
      const status = drive && drive.status ? condensedCase(drive.status) : null;

      // Check statuses and return a formatted string
      if (status) {
        if (status === `dispatched`) return `Dispatched`;
        if (status === `pickupstarted`) return `Pickup Started`;
        if (status === `pickuparrived`) return `Pickup Arrived`;
        if (status === `pickupsuccessful`) return `Pickup Successful`;
        if (status === `deliverystarted`) return `Delivery Started`;
        if (status === `deliveryarrived`) return `Delivery Arrived`;
        if (status === `deliverysuccessful`) return `Delivery Successful`;
        if (status === `canceled` || status === `cancelled`) return `Canceled`;
        if (status === `failed`) return `Failed`;
        return capEach(drive.status);
      }
    }
    return fallbackStatus;
  };

  /** Get formatted cancel status string from a drive */
  const getFormattedCancelStatusFromDrive = (drive = null) => {
    // Default fallback status string when not enough info is provided
    let fallbackStatus = `Unknown`;

    // Check for drive
    if (drive) {
      // Set local variables
      fallbackStatus = `Not Canceled`;
      const cancelStatus = drive && drive.cancel_status ? condensedCase(drive.cancel_status) : null;

      // Check statuses and return a formatted string
      if (cancelStatus) {
        if (cancelStatus === `pending`) return `Pending Cancel`;
        if (cancelStatus === `seen`) return `Cancel Was Seen`;
        if (cancelStatus === `canceled` || cancelStatus === `cancelled`) return `Cancel Before Started`;
        if (cancelStatus === `started`) return `Cancel After Started`;
        if (cancelStatus === `delivered`) return `Cancel After Delivered`;
        return capEach(drive.cancel_status);
      }
    }
    return fallbackStatus;
  };

  /** Get formatted status string from a ride */
  const getFormattedStatusFromRide = (ride = null) => {
    // Default fallback status string when not enough info is provided
    let fallbackStatus = `Unknown`;

    // Check for ride
    if (ride) {
      // Set local variables
      fallbackStatus = `Not Called`;
      const status = ride.status ? condensedCase(ride.status) : null;

      // Check statuses and return a formatted string
      if (status) {
        if (status === `awaitingresponse` || status === `awaiting response` || status === `awaiting_response`)
          return `Awaiting Response`;
        if (status === `pending`) return `Pending`;
        if (status === `processing`) return `Processing`;
        if (status === `estimating`) return `Estimating`;
        if (status === `estimated`) return `Estimated`;
        if (status === `accepted`) return `Accepted`;
        if (status === `arrived`) return `Arrived`;
        if (status === `pickedup` || status === `picked up` || status === `picked_up`) return `Picked Up`;
        if (status === `droppedoff` || status === `dropped off` || status === `dropped_off`) return `Dropped Off`;
        if (status === `notchosen` || status === `not chosen` || status === `not_chosen`) return `Not Chosen`;
        if (status === `canceled` || status === `cancelled`) return `Canceled`;
        if (status === `failed`) return `Failed`;
        return capEach(ride.status);
      }
    }
    return fallbackStatus;
  };

  /** Get formatted status string from a plan */
  const getFormattedStatusFromPlan = plan => {
    if (!plan || !plan.moves) return null;

    //Find first move whose delivery_time is not in the past
    let currentMove = plan.moves.find(m => dayjs(m.delivery_time) > dayjs());
    if (currentMove) return getFormattedCombinedStatusFromMove(currentMove);
    return null;
  };

  /** Get formatted status string from a driver */
  const getFormattedStatusFromDriver = driver => {
    // Default fallback status string when not enough info is provided
    let fallbackStatus = `Unknown`;

    // Check for driver
    if (driver) {
      // Set local variables
      const status = driver && driver.status ? condensedCase(driver.status) : null;

      // Check statuses and return a formatted string
      if (status) {
        if (status === `new`) return `New`;
        if (status === `interviewed`) return `Interviewed`;
        if (status === `onboarding`) return `Onboarding`;
        if (status === `training`) return `Training`;
        if (status === `offline`) return `Offline`;
        if (status === `online`) return `Online`;
        if (status === `rejected`) return `Rejected`;
        if (status === `terminated`) return `Terminated`;
        return capEach(driver.status);
      }
    }
    return fallbackStatus;
  };

  /** Get formatted vehicle string from the move object */
  const getFormattedVehicleFromMove = (move = null, includeYear = true, includeColor = true) => {
    // Default fallback vehicle string when not enough info is provided
    const fallbackVehicle = `Vehicle`;

    // Check for move
    if (move) {
      // Set local variables
      const year = move.vehicle_year || move.driver_vehicle_year || null;
      const make = move.vehicle_make || move.driver_vehicle_make || null;
      const model = move.vehicle_model || move.driver_vehicle_model || null;
      const color = move.vehicle_color || move.driver_vehicle_color || null;

      // Set local vehicle string
      let vehicle = ``;

      // Check for make and model
      if (make && model) vehicle = `${make} ${model}`;
      else if (make) vehicle = make;
      else if (model) vehicle = model;
      else vehicle = fallbackVehicle;

      // Include color and year if specified
      if (includeYear && year) vehicle = `${year} ${vehicle}`;
      if (includeColor && color) vehicle = `${vehicle} (${capFirst(color)})`;

      // Return the built vehicle string
      return vehicle;
    }
    return fallbackVehicle;
  };

  /** Check if the move is in-progress */
  const checkMoveInProgress = (move, includeCompleted = false) => {
    if (move) {
      const moveType = condensedCase(move.move_type) || ``;
      const status = condensedCase(move.status) || ``;

      if (moveType === `drive`) {
        if ((status.includes(`pickup`) || status.includes(`delivery`)) && status !== `deliverysuccessful`) return true;
      }

      if (moveType === `ride`) {
        if (status === `accepted` || status === `arrived` || status === `pickedup`) return true;
      }

      if (includeCompleted) return checkMoveCompleted(move);
    }
    return false;
  };

  /** Check if the move was completed */
  const checkMoveCompleted = move => {
    if (move) {
      const moveType = condensedCase(move.move_type) || ``;
      const status = condensedCase(move.status) || ``;

      if (moveType === `drive` && status === `deliverysuccessful`) return true;
      if (moveType === `ride` && status === `droppedoff`) return true;
    }
    return false;
  };

  /** Get the region id from a move - From the lane's pickup or delivery */
  const getRegionIdFromMove = (move = null) => {
    if (move) {
      const regionId =
        getPropValue(move, `lane.pickup.region_id`) ||
        getPropValue(move, `lane.pickup.region.id`) ||
        getPropValue(move, `lane.delivery.region_id`) ||
        getPropValue(move, `lane.delivery.region.id`);
      return regionId || null;
    }
    return null;
  };

  /** Get the region id from a move - From the lane's pickup or delivery */
  const getRegionNameFromMove = (move = null) => {
    if (move) {
      const regionName =
        getPropValue(move, `lane.pickup.region.name`) || getPropValue(move, `lane.delivery.region.name`);
      return regionName || null;
    }
    return null;
  };

  /** Get the latest timestamp from a move -  */
  const getLatestTimestampFromMove = (move = null) => {
    if (move) {
      if (move.move_failed) return move.move_failed;
      if (move.delivery_successful) return move.delivery_successful;
      if (move.delivery_arrived) return move.delivery_arrived;
      if (move.delivery_started) return move.delivery_started;
      if (move.pickup_successful) return move.pickup_successful;
      if (move.pickup_arrived) return move.pickup_arrived;
      if (move.pickup_started) return move.pickup_started;
    }
    return null;
  };

  /** Get driver name from a move/driver/user object */
  const getDriverName = (obj = null) => {
    if (obj) {
      if (getPropValue(obj, `name`)) return obj.name;
      if (getPropValue(obj, `driver_name`)) return obj.driver_name;
      if (getPropValue(obj, `display_name`)) return obj.display_name;
      if (getPropValue(obj, `driver.name`)) return obj.driver.name;
      if (getPropValue(obj, `driver.driver_name`)) return obj.driver.driver_name;
      if (getPropValue(obj, `driver.display_name`)) return obj.driver.display_name;
      if (getPropValue(obj, `user.name`)) return obj.user.name;
      if (getPropValue(obj, `user.driver_name`)) return obj.user.driver_name;
      if (getPropValue(obj, `user.display_name`)) return obj.user.display_name;
      if (getPropValue(obj, `driver.user.name`)) return obj.driver.user.name;
      if (getPropValue(obj, `driver.user.driver_name`)) return obj.driver.user.driver_name;
      if (getPropValue(obj, `driver.user.display_name`)) return obj.driver.user.display_name;
    }
    return null;
  };

  /** Get driver avatar from a move/driver/user object */
  const getDriverAvatar = (obj = null) => {
    if (obj) {
      if (getPropValue(obj, `avatar_url`)) return obj.avatar_url;
      if (getPropValue(obj, `driver.avatar_url`)) return obj.driver.avatar_url;
      if (getPropValue(obj, `user.avatar_url`)) return obj.user.avatar_url;
      if (getPropValue(obj, `driver.user.avatar_url`)) return obj.driver.user.avatar_url;
    }
    return null;
  };

  /** Get the driver's first name from the display_name */
  const getDriverFirstNameFromDisplayName = (displayName = null) => {
    if (displayName) {
      const splitArr = displayName.split(` `);
      if (splitArr.length) {
        const firstName = splitArr[0];
        return firstName;
      }
    }
    return null;
  };

  /** Get the driver's middle name from the display_name */
  const getDriverMiddleNameFromDisplayName = (displayName = null) => {
    if (displayName) {
      const splitArr = displayName.split(` `);
      if (splitArr.length > 2) {
        const middleName = splitArr[1];
        return middleName;
      }
    }
    return null;
  };

  /** Get the driver's last name from the display_name */
  const getDriverLastNameFromDisplayName = (displayName = null) => {
    if (displayName) {
      const splitArr = displayName.split(` `);
      if (splitArr.length > 1) {
        const lastName = splitArr[splitArr.length - 1];
        return lastName;
      }
    }
    return null;
  };

  /** Get driver initials string from an object */
  const getDriverInitials = (obj = null) => {
    // Default fallback initials string when not enough info is provided
    const fallbackInitials = `N/A`;

    // Check for obj
    if (obj) {
      // Set local variables
      const fullName = getDriverName(obj) || fallbackInitials;

      // Get/Set initials
      if (fullName !== fallbackInitials) {
        // Split the full name into first, middle and last
        const firstName = getDriverFirstNameFromDisplayName(fullName);
        const middleName = getDriverMiddleNameFromDisplayName(fullName);
        const lastName = getDriverLastNameFromDisplayName(fullName);

        // Assign each initial
        const firstI = firstName ? firstName[0] : ``;
        const middleI = middleName ? middleName[0] : ``;
        const lastI = lastName ? lastName[0] : ``;

        // Return built initials
        const initials = `${firstI}${middleI}${lastI}`.toUpperCase();
        if (initials && initials !== ``) return initials;
      }
    }
    return fallbackInitials;
  };

  /** Combine the driver object with child objects to make the data easier to use */
  const spreadDriverObj = (driver = null) => {
    let spreadDriver = {};
    if (driver) {
      if (driver.driverdetail) {
        spreadDriver = {
          ...spreadDriver,
          ...driver.driverdetail,
        };
      }

      if (driver.region) {
        if (!spreadDriver.region_id) spreadDriver.region_id = driver.region.id || null;
        if (!spreadDriver.region_name) spreadDriver.region_name = driver.region.name || null;
      }

      if (driver.user) {
        spreadDriver = {
          ...spreadDriver,
          ...driver.user,
        };
      }

      spreadDriver = {
        ...spreadDriver,
        ...driver,
      };

      delete spreadDriver.driverdetail;
      delete spreadDriver.region;
      delete spreadDriver.user;

      if (!spreadDriver.config) spreadDriver.config = {};
      if (!spreadDriver.config.attributes) spreadDriver.config.attributes = {};
      if (!spreadDriver.verification) spreadDriver.verification = {};
      if (!spreadDriver.veteran) spreadDriver.veteran = {};

      return spreadDriver;
    }
    return spreadDriver;
  };

  /** Get the general pickup or delivery time from the move to display to the user */
  const getPickupOrDeliveryTimeFromMove = (type = `pickup`, move = null, format = `MM/DD/YYYY hh:mm A z`) => {
    if (move) {
      if (type === `pickup`) {
        if (move.pickup_arrived) return dayjs(move.pickup_arrived).format(format);
        if (move.pickup_time) return dayjs(move.pickup_time).format(format);
        if (move.ready_by) return dayjs(move.ready_by).format(format);
      }
      if (type === `delivery`) {
        if (move.delivery_successful) return dayjs(move.delivery_successful).format(format);
        if (move.delivery_time) return dayjs(move.delivery_time).format(format);
        if (move.deliver_by) return dayjs(move.deliver_by).format(format);
      }
    }
    return null;
  };

  /** Get formatted type string from the location object */
  const getFormattedTypeFromLocation = (location = null) => {
    // Default fallback status string when not enough info is provided
    const fallbackStatus = `-`;

    // Check for location
    if (location) {
      // Set local variables
      const type = condensedCase(location.type) || ``;

      // Check type and return a formatted string
      if (type && type !== ``) {
        if (type === `customer`) return `Standard`;
        else if (type === `consumerbusiness`) return `Consumer Business`;
        else if (type === `consumerresidential`) return `Consumer Residential`;
        else return capEach(location.type);
      }
    }
    return fallbackStatus;
  };

  /** Get a location from a list by passing in the id and list to look in */
  const getLocationByIdFromList = (locationId, locations) => {
    if (locationId) {
      const foundLocation = locations.find(pl => pl.id === locationId);
      if (foundLocation) return foundLocation;
    }
    return null;
  };

  /** Get the accessorial in pending status by passing in the accessorials array */
  const getPendingAccessorial = accs => {
    return accs.find(acc => acc.status === 'pending' && acc.authorization);
  };

  /** Get preferred name of location */
  const getNameFromLocation = (location = null) => {
    const fallbackName = `Unknown Location`;

    if (location) {
      let locationName = fallbackName;

      if (location.name) locationName = location.name;
      // if (location.nickname) locationName = location.nickname;

      return locationName;
    }

    return fallbackName;
  };

  /** Get drive type from move */
  const getDriveTypeFromMove = (move = null) => {
    const fallbackType = `ops`;

    if (move) {
      let driveType = fallbackType;

      if (move.consumer_pickup && move.consumer_type === `customer`) driveType = `concierge`;
      if (move.consumer_type === `loaner`) driveType = `loaner`;

      return driveType;
    }

    return fallbackType;
  };

  /** Find the duration between two times (in seconds) */
  const durationBetween = (timeOne = null, timeTwo = null) => {
    if (timeOne && timeTwo) {
      const formattedTimeOne = dayjs(timeOne).format();
      const formattedTimeTwo = dayjs(timeTwo);
      const durationSec = formattedTimeTwo.diff(formattedTimeOne, `second`);
      return durationSec;
    }
    return 0;
  };

  /** Get duration (in minutes) from specified lane */
  const getDurationInMinutes = (seconds = 0, precision = 0) => {
    const fallbackDuration = `0 min`;

    if (seconds) {
      return `${Number(seconds / 60).toFixed(precision)} min`;
    }

    return fallbackDuration;
  };

  /** Get the number of minutes between 2 timestamps */
  const getMinutesBetween = (startTimestamp = null, endTimestamp = null) => {
    if (startTimestamp && endTimestamp) {
      let start = dayjs(startTimestamp);
      let end = dayjs(endTimestamp);
      if (start && end) {
        let dur = end.diff(start);
        let mins = Math.round(Math.abs(dayjs.duration(dur).asMinutes()));
        return mins;
      }
    }
    return 0;
  };

  /** Get the last N digits of a string (N defautls to 8) */
  const getLastNDigits = (str = null, n = 8) => {
    if (str && typeof str === `string` && str.length > n) return str.slice(-n);
    return str;
  };

  /** Get a cleansed text string (remove underscores and cap all) */
  const getReadableText = (str = null, nbsp = false) => {
    if (str) {
      let newStr = str.split(/[.\s_-]/g);
      newStr = newStr.map(val => capFirst(val).trim());
      return newStr.join(nbsp ? `\xa0` : ` `);
    }
    return str;
  };

  /** Get snake case from a string (underscore separators + lowercase) */
  const getSnakeCase = (str = null, upperCase = false) => {
    if (str) {
      let newStr = str.split(/[,.\s-]/g);
      newStr = newStr.map(val => val.trim());
      newStr = newStr.join(`_`);
      return upperCase ? newStr.toUpperCase() : newStr.toLowerCase();
    }
    return str;
  };

  /** Get a cleansed monetary value from a number or string number */
  const formatUSD = (value = null, options = {}) => {
    const { returnNull = false, removeSign = false, removeCommas = false, removeCents = false } = options;

    // Check for value and return null
    if (!value && returnNull) return null;

    // Remove spaces and commas so the value can be converted to a number
    // Build the cleansed monetary string from the numeric value
    const replacedValue = `${value}`.replace(/[,]/g, ``);
    const numValue = Number(replacedValue) || 0;
    let cleansedValue = '$' + numValue.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, `$1,`);

    // Additional options
    if (removeSign) cleansedValue = cleansedValue.replace(/[$]/g, ``);
    if (removeCommas) cleansedValue = cleansedValue.replace(/[,]/g, ``);
    if (removeCents) cleansedValue = cleansedValue.split(`.`)[0];

    // Return cleansed monetary value
    return cleansedValue;
  };

  /** Get a cleansed phone number from a string */
  const formatPhone = (str = null, removeCountry = false) => {
    if (str) {
      let cleaned = ('' + str).replace(/\D/g, '');
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? '+1 ' : '';
        return [removeCountry ? `` : intlCode, '(', match[2], ')\xa0', match[3], '-\u2060', match[4]].join('');
      }
    }
    return str;
  };

  /** Get a cleansed phone type from a string */
  const formatPhoneType = (str = null) => {
    if (str) {
      if (str === `android` || str === `droid`) return `Android`;
      if (str === `ios` || str === `iphone` || str === `apple`) return `iOS`;
    }
    return str;
  };

  /** Replace spaces with non-breaking spaces */
  const getNonBreakingWord = (str = null) => {
    if (str) {
      let newStr = str.replace(/\s/g, '\xa0');
      return newStr;
    }
    return str;
  };

  /** Parse move workflow data */
  const getWorkflowData = (type, workflow_data, format = `csv`, keyValue = false) => {
    const typeStr = type ? `${type}_` : `extra_`;

    if (workflow_data && typeof workflow_data === `object`) {
      const workflowDataKeys = workflow_data ? Object.keys(workflow_data) : [];
      const workflowDataVals = workflowDataKeys.map(key => workflow_data[key]) || [];

      if (keyValue) {
        const workflowData = workflowDataKeys.map((key, i) => {
          let formattedKey = key;
          if (format === `csv`) formattedKey = typeStr + getSnakeCase(key);
          if (format === `move_details`) formattedKey = getReadableText(key, true);
          return { index: i, key: formattedKey, val: workflowDataVals[i] };
        });

        return workflowData || [];
      } else {
        let workflowData = {};
        workflowDataKeys.forEach((key, i) => {
          let formattedKey = key;
          if (format === `csv`) formattedKey = typeStr + getSnakeCase(key);
          if (format === `move_details`) formattedKey = getReadableText(key, true);
          workflowData[formattedKey] = workflowDataVals[i];
        });

        return workflowData || {};
      }
    }
    return null;
  };

  /** Check a number to see if its negative, if so, clamp it to 0 */
  const clampNegNum = (num = null) => {
    if (num && !isNaN(parseFloat(num)) && num > 0) return num;
    return 0;
  };

  /** Copy a string to the clipboard */
  const copyToClipboard = (str = null) => {
    try {
      if (str) {
        navigator.clipboard.writeText(str);
        toast.info(`Copied text "${str}"`, { autoClose: 2500 });
      } else toast.warning(`No text was found to copy!`);
    } catch (err) {
      toast.error(`Failed to copy text!`);
      console.error(`Failed to copy text:`, err);
    }
  };

  const circularJSONStringify = (json, spaces = 2, hiddenProperties = []) => {
    let cache = [];
    let formattedJson = '';
    try {
      formattedJson = JSON.stringify(
        json,
        (key, value) => {
          if (typeof value === 'object' && value !== null) {
            // Duplicate reference found, discard key
            if (cache.includes(value)) return;

            // Store value in our collection
            cache.push(value);
          }
          return hiddenProperties.includes(key) ? 'hidden by circularStringify()' : value;
        },
        spaces
      );
    } catch (error) {
      console.error(`Failed doing JSON.stringify() on circular object: ${error.message}`, json, error);
    } finally {
      cache = null;
      return formattedJson;
    }
  };

  /** Generate a string array of years */
  const genListOfYears = () => {
    const year = dayjs().add(1, 'y').format('YYYY');
    const till = dayjs().subtract(40, 'y').format('YYYY');
    let yearList = [];
    for (var y = year; y >= till; y--) {
      yearList.push(`${y}`);
    }
    return yearList;
  };

  /** Generate a string array of colors */
  const genListOfColors = () => {
    const listOfColors = [
      `Black`,
      `Blue`,
      `Brown`,
      `Burgundy`,
      `Gold`,
      `Gray`,
      `Green`,
      `Orange`,
      `Pink`,
      `Purple`,
      `Red`,
      `Silver`,
      `Tan`,
      `White`,
      `Yellow`,
    ];
    return listOfColors;
  };

  /** Generate a string array of states and territories */
  const genListOfStates = (includeDC = false, includeTerritories = false) => {
    let listOfStates = [
      { initials: `AL`, full: `Alabama` },
      { initials: `AK`, full: `Alaska` },
      { initials: `AZ`, full: `Arizona` },
      { initials: `AR`, full: `Arkansas` },
      { initials: `CA`, full: `California` },
      { initials: `CO`, full: `Colorado` },
      { initials: `CT`, full: `Connecticut` },
      { initials: `DE`, full: `Delaware` },
      { initials: `FL`, full: `Florida` },
      { initials: `GA`, full: `Georgia` },
      { initials: `HI`, full: `Hawaii` },
      { initials: `ID`, full: `Idaho` },
      { initials: `IL`, full: `Illinois` },
      { initials: `IN`, full: `Indiana` },
      { initials: `IA`, full: `Iowa` },
      { initials: `KS`, full: `Kansas` },
      { initials: `KY`, full: `Kentucky` },
      { initials: `LA`, full: `Louisiana` },
      { initials: `ME`, full: `Maine` },
      { initials: `MD`, full: `Maryland` },
      { initials: `MA`, full: `Massachusetts` },
      { initials: `MI`, full: `Michigan` },
      { initials: `MN`, full: `Minnesota` },
      { initials: `MS`, full: `Mississippi` },
      { initials: `MO`, full: `Missouri` },
      { initials: `MT`, full: `Montana` },
      { initials: `NE`, full: `Nebraska` },
      { initials: `NV`, full: `Nevada` },
      { initials: `NH`, full: `New Hampshire` },
      { initials: `NJ`, full: `New Jersey` },
      { initials: `NM`, full: `New Mexico` },
      { initials: `NY`, full: `New York` },
      { initials: `NC`, full: `North Carolina` },
      { initials: `ND`, full: `North Dakota` },
      { initials: `OH`, full: `Ohio` },
      { initials: `OK`, full: `Oklahoma` },
      { initials: `OR`, full: `Oregon` },
      { initials: `PA`, full: `Pennsylvania` },
      { initials: `RI`, full: `Rhode Island` },
      { initials: `SC`, full: `South Carolina` },
      { initials: `SD`, full: `South Dakota` },
      { initials: `TN`, full: `Tennessee` },
      { initials: `TX`, full: `Texas` },
      { initials: `UT`, full: `Utah` },
      { initials: `VT`, full: `Vermont` },
      { initials: `VA`, full: `Virginia` },
      { initials: `WA`, full: `Washington` },
      { initials: `WV`, full: `West Virginia` },
      { initials: `WI`, full: `Wisconsin` },
      { initials: `WY`, full: `Wyoming` },
    ];

    if (includeDC) {
      listOfStates.push({ initials: `DC`, full: `District of Columbia` });
    }

    if (includeTerritories) {
      listOfStates = [
        ...listOfStates,
        { initials: `AS`, full: `American Samoa` },
        { initials: `GU`, full: `Guam` },
        { initials: `MP`, full: `Northern Mariana Islands` },
        { initials: `PR`, full: `Puerto Rico` },
        { initials: `VI`, full: `U.S. Virgin Islands` },
        { initials: `UM`, full: `U.S. Minor Outlying Islands` },
      ];
    }

    return listOfStates;
  };

  // Return hook logic
  return {
    condensedCase,
    capFirst,
    capEach,
    capAll,
    round,
    checkNeg,
    getFormattedRidesharePartner,
    getFormattedCombinedStatusFromMove,
    getFormattedStatusFromDrive,
    getFormattedCancelStatusFromDrive,
    getFormattedStatusFromRide,
    getFormattedStatusFromPlan,
    getFormattedStatusFromDriver,
    getFormattedVehicleFromMove,
    checkMoveInProgress,
    checkMoveCompleted,
    getRegionIdFromMove,
    getRegionNameFromMove,
    getLatestTimestampFromMove,
    getDriverName,
    getDriverAvatar,
    getDriverFirstNameFromDisplayName,
    getDriverMiddleNameFromDisplayName,
    getDriverLastNameFromDisplayName,
    getDriverInitials,
    spreadDriverObj,
    getPickupOrDeliveryTimeFromMove,
    getFormattedTypeFromLocation,
    getLocationByIdFromList,
    getPendingAccessorial,
    getNameFromLocation,
    getDriveTypeFromMove,
    durationBetween,
    getDurationInMinutes,
    getMinutesBetween,
    getLastNDigits,
    getReadableText,
    getSnakeCase,
    formatUSD,
    formatPhone,
    formatPhoneType,
    getNonBreakingWord,
    getWorkflowData,
    clampNegNum,
    copyToClipboard,
    circularJSONStringify,
    genListOfYears,
    genListOfColors,
    genListOfStates,
  };
}
