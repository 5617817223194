import React from 'react';
import { makeStyles, Container, Typography, Icon } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import useOnboarding from './useOnboarding';

import PageRoot from '../../reusable/PageRoot';
import Workflow from './Workflow';
import LaunchPadGroup from './LaunchPadGroup';

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function LaunchPad({ driver, refetch }) {
  const cls = useStyles();

  const { buildLaunchpadGroups, updateDriverStatus } = useOnboarding();

  const [workflow, setWorkflow] = React.useState({ open: false, flowGroup: null, flowSection: null, output: null });
  const handleWorkflowOpen = (flowGroup, flowSection) => {
    setWorkflow({ open: true, flowGroup, flowSection, output: null });
  };
  const handleWorkflowClose = output => {
    setWorkflow({ open: false, flowGroup: null, flowSection: null, output: output });
  };

  const launchpadGroups = buildLaunchpadGroups(driver, handleWorkflowOpen);

  // Set driver status to "onboarding" if necessary
  React.useEffect(() => {
    if (driver && (driver.status === `new` || driver.status === `interviewed`)) {
      updateDriverStatus(driver, `onboarding`, () => refetch());
    }
  }, [driver]);

  return (
    <>
      {!workflow.open ? (
        <PageRoot>
          <Container maxWidth='md'>
            <div className={cls.launchPad}>
              <Typography className={cls.title}>
                {driver.display_name ? `Welcome, ${driver.display_name}!` : `Driver LaunchPad`}
              </Typography>

              <Typography className={cls.subtitle}>
                Please fill out all required forms and documentation. When approved, pay the onboarding fee and begin
                your training. If you have any issues filling out these forms, feel free to<b> TEXT </b>our dispatcher
                line at&nbsp;
                <a className={cls.phone} href='tel:8009137674'>
                  (800) 913-7674
                </a>
                .
              </Typography>

              {driver.status === `rejected` ? (
                <>
                  <Spacer size='md' />

                  <div className={cls.alertBox}>
                    <Icon className={cls.alertErrorIcon}>report</Icon>
                    <Typography className={cls.alertTxt}>
                      Your application is currently on hold. A member of the HopDrive team will reach out to you.
                    </Typography>
                  </div>
                </>
              ) : (
                <>
                  <Spacer size='md' />

                  <div className={cls.alertBox}>
                    <Icon className={cls.alertIcon}>error</Icon>
                    <Typography className={cls.alertTxt}>
                      When you complete a form group and are satisfied with the information you filled in, please click
                      the
                      <b> FINALIZE </b>button in the top-right of each group. This will let our team know your
                      information is ready to be reviewed.
                    </Typography>
                  </div>

                  <Spacer size='xl' />

                  {launchpadGroups && launchpadGroups.length
                    ? launchpadGroups.map((g, i) => (
                        <React.Fragment key={`launchpad-group-${i}`}>
                          {i > 0 ? <Spacer size='xl' /> : null}
                          <LaunchPadGroup index={i} group={g} refetch={refetch} />
                        </React.Fragment>
                      ))
                    : null}
                </>
              )}
            </div>
          </Container>
        </PageRoot>
      ) : (
        <Workflow
          driver={driver}
          flowGroup={workflow.flowGroup}
          flowSection={workflow.flowSection}
          onClose={handleWorkflowClose}
          refetch={refetch}
        />
      )}
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = makeStyles(theme => ({
  launchPad: {
    position: 'relative',
  },

  title: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1.333,
    fontSize: 24,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      fontSize: 18,
    },
  },
  subtitle: {
    lineHeight: 1.333,
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },

  alertBox: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.dark,
  },
  alertIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  alertErrorIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.error.main,
  },
  alertTxt: {
    lineHeight: 1.333,
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },

  phone: {
    color: theme.palette.info.main,
  },
}));
