import React from 'react';
import { toast } from 'react-toastify';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import GlobalContext from '../../../GlobalContext';
import {
  useTheme,
  makeStyles,
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  Checkbox,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import gql from 'graphql-tag';

import useValidation from '../../../hooks/useValidation';
import useFormat from '../../../hooks/useFormat';
import useOnboarding from './useOnboarding';

import WorkflowBar from './WorkflowBar';

const log = false;

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function FormInsuranceInfo({ driver = {}, flowGroup, flowSection, onClose, refetch }) {
  const theme = useTheme();
  const ctx = React.useContext(GlobalContext);
  const cls = useStyles();

  // Hook functions
  const { validateNumber } = useValidation();
  const { formatString, formatNumber } = useFormat();
  const { proceedToPrevWorkflow, proceedToNextWorkflow } = useOnboarding();

  // General form state
  const [editMode, setEditMode] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [validation, setValidation] = React.useState({});

  // Value form State
  const [coverageAmount, setCoverageAmount] = React.useState(``);
  const [policyId, setPolicyId] = React.useState(``);
  const [provider, setProvider] = React.useState(``);
  const [rideshareRider, setRideshareRider] = React.useState(null);

  // Update state with driver fields (when driver changes)
  React.useEffect(() => {
    if (driver) {
      setCoverageAmount(driver.insurance_coverage_amount || ``);
      setPolicyId(driver.insurance_policy_id || ``);
      setProvider(driver.insurance_provider || ``);
      setRideshareRider(driver.insurance_rideshare_rider);
    }
  }, [driver]);

  // Checks if all inputs are valid
  const handleValidateForm = () => {
    let valid = true;

    const newValidation = {
      coverageAmount: validateNumber(coverageAmount),
      policyId: policyId ? true : false,
      provider: provider ? true : false,
      rideshareRider: rideshareRider === true || rideshareRider === false,
    };
    setValidation(newValidation);

    Object.keys(newValidation).forEach((key, i) => {
      const value = Object.values(newValidation)[i];
      if (value === false) {
        valid = false;
        setIsSaving(false);
      }
    });

    return valid;
  };

  // Mutation handler
  const saveInsurance = async () => {
    const variables = {
      driverId: driver.id,
      driverdetailInput: {
        insurance_coverage_amount: coverageAmount || null,
        insurance_policy_id: policyId || null,
        insurance_provider: provider || null,
        insurance_rideshare_rider: rideshareRider,
      },
    };

    log && console.log(`saveInsurance - Variables:`, variables);

    try {
      const res = await ctx.apolloClient.mutate({
        mutation: SAVE_INSURANCE,
        variables: variables,
      });

      if (getPropValue(res, `data.update_driverdetails.affected_rows`)) {
        if (refetch) refetch();
        return { success: true };
      }

      const errStr = `Failed to update information. Please contact support.`;
      console.error(errStr);
      return { success: false, error: errStr };
    } catch (err) {
      const errStr = `Failed to update information. Please contact support.`;
      console.error(`Error updating information:`, err);
      return { success: false, error: errStr };
    }
  };

  // Handle saving the form
  const handleSave = async (proceed = false) => {
    const res = await saveInsurance();
    if (res && res.success === true) {
      setIsSaving(false);
      toast.success(`Information saved!`, { autoClose: 2500 });
      if (proceed) {
        proceedToNextWorkflow(flowGroup, flowSection.id, onClose);
        return;
      }
      if (onClose) {
        onClose();
        return;
      }
    }
    if (res && res.error) {
      toast.error(res.error);
    }
  };

  // List of button actions in the workflow bar
  const actions = [
    {
      label: `Save`,
      icon: `save`,
      color: `secondary`,
      tip: `Save changes & return to the LaunchPad.`,
      handler: async () => {
        setIsSaving(true);
        const valid = handleValidateForm();
        if (valid) await handleSave();
        else toast.error(`Failed to save. Please check the form for errors.`);
      },
      hide: !editMode,
    },
    {
      label: `Save & Proceed`,
      icon: `double_arrow`,
      color: `primary`,
      tip: `Save changes & proceed to the next section.`,
      handler: async () => {
        setIsSaving(true);
        const valid = handleValidateForm();
        if (valid) await handleSave(true);
        else toast.error(`Failed to save. Please check the form for errors.`);
      },
      hide: !editMode,
    },
    {
      label: `Back`,
      icon: `arrow_back`,
      variant: `outlined`,
      color: `secondary`,
      tip: `Jump to the previous section.`,
      handler: () => proceedToPrevWorkflow(flowGroup, flowSection.id, onClose),
      hide: editMode,
    },
    {
      label: `Forward`,
      icon: `arrow_forward`,
      variant: `outlined`,
      color: `primary`,
      tip: `Jump to the next section.`,
      handler: () => proceedToNextWorkflow(flowGroup, flowSection.id, onClose),
      hide: editMode,
    },
  ];

  return (
    <>
      <WorkflowBar
        groupTitle={flowGroup.label}
        sectionTitle={flowSection.label}
        actions={actions}
        isSaving={isSaving}
        onClose={onClose}
        // editMode={editMode}
        // setEditMode={setEditMode}
      />

      <div className={cls.workflowPad} />

      <Spacer size='xxl' />

      <Container maxWidth='md'>
        <div className={cls.workflow}>
          {/* INSURANCE POLICY INFORMATION */}

          <Typography className={cls.title}>Insurance Policy</Typography>

          <Typography className={cls.subtitle}>
            HopDrive drivers are required to have a personal auto insurance policy that meets minimum state coverage
            requirements. Keep in mind that certain personal auto policies may not cover you while you’re driving with
            HopDrive.
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  Provider<span style={{ color: theme.palette.error.main }}> *</span>
                </Typography>
              </div>

              <TextField
                disabled={!editMode}
                required
                fullWidth
                placeholder='Enter provider...'
                variant='outlined'
                size='small'
                value={provider}
                onChange={e => setProvider(e.target.value)}
                onBlur={() => setProvider(formatString(provider))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        label
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.provider === false}
                helperText={validation.provider === false ? `Provider is invalid` : null}
              />
            </Grid>

            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  Policy ID<span style={{ color: theme.palette.error.main }}> *</span>
                </Typography>
              </div>

              <TextField
                disabled={!editMode}
                required
                fullWidth
                placeholder='Enter policy ID...'
                variant='outlined'
                size='small'
                value={policyId}
                onChange={e => setPolicyId(e.target.value)}
                onBlur={() => setPolicyId(formatString(policyId))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        tag
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.policyId === false}
                helperText={validation.policyId === false ? `Policy ID is invalid` : null}
              />
            </Grid>

            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  Liability Coverage Amount<span style={{ color: theme.palette.error.main }}> *</span>
                </Typography>
              </div>

              <TextField
                disabled={!editMode}
                required
                fullWidth
                placeholder='Enter liability coverage amount...'
                variant='outlined'
                size='small'
                type='number'
                value={coverageAmount}
                onChange={e => setCoverageAmount(e.target.value)}
                onBlur={() => setCoverageAmount(formatNumber(coverageAmount))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        attach_money
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.coverageAmount === false}
                helperText={validation.coverageAmount === false ? `Liability coverage amount is invalid` : null}
              />
            </Grid>
          </Grid>

          <Spacer size='xxl' />

          {/* RIDESHARE RIDER INFORMATION */}

          <Typography className={cls.title}>Rideshare Rider Coverage</Typography>

          <Typography className={cls.subtitle}>
            Rideshare coverage protects you and your vehicle if you drive for a ridesharing service such as Uber and
            Lyft. While ridesharing companies may provide some insurance that applies to drivers operating on their
            platforms, their coverage may be limited while you're waiting to match with a rider or when you have a
            passenger in your vehicle.
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  Rideshare Rider Coverage<span style={{ color: theme.palette.error.main }}> *</span>
                </Typography>
              </div>

              {validation.rideshareRider === false ? (
                <Typography className={cls.errorMessage}>Please select an option below</Typography>
              ) : null}

              <div className={cls.checkDiv}>
                <Checkbox
                  disabled={!editMode}
                  className={cls.checkBox}
                  checked={rideshareRider === true}
                  onChange={() => setRideshareRider(true)}
                  icon={<Icon>radio_button_unchecked</Icon>}
                  checkedIcon={<Icon>radio_button_checked</Icon>}
                />
                <Typography className={cls.checkTxt}>
                  Yes, I have rideshare rider coverage on my insurance policy.
                </Typography>
              </div>

              <div className={cls.checkDiv}>
                <Checkbox
                  disabled={!editMode}
                  className={cls.checkBox}
                  checked={rideshareRider === false}
                  onChange={() => setRideshareRider(false)}
                  icon={<Icon>radio_button_unchecked</Icon>}
                  checkedIcon={<Icon>radio_button_checked</Icon>}
                />
                <Typography className={cls.checkTxt}>
                  No, I do not have rideshare rider coverage on my insurance policy.
                </Typography>
              </div>
            </Grid>
          </Grid>

          <Spacer size='xxl' />
        </div>
      </Container>

      <Spacer size='xl' />
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = makeStyles(theme => ({
  workflowPad: {
    width: '100%',
    height: 56,
  },

  workflow: {
    position: 'relative',
    width: '100%',
    // padding: theme.spacing(2),
    // borderRadius: theme.shape.paperRadius,
    // background: theme.palette.background.paper,
    // boxShadow: theme.shadow.medium,
  },

  title: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  subtitle: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1.25,
    fontSize: 14,
    // color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },

  label: {
    display: 'flex',
  },
  labelTxt: {
    verticalAlign: 'top',
    margin: '4px 0',
    lineHeight: 1,
    fontSize: 14,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      margin: '3px 0',
      fontSize: 12,
    },
  },
  labelHelp: {
    display: 'block',
    verticalAlign: 'top',
    marginLeft: 'auto',
    lineHeight: 1,
    fontSize: 20,
    color: theme.palette.text.disabled,
    '&:hover': {
      color: theme.palette.text.primary,
    },
    transition: '0.15s',
    cursor: 'pointer',
  },

  adornment: {
    verticalAlign: 'top',
    marginBottom: 6,
  },
  adornmentOutlined: {
    verticalAlign: 'top',
  },
  placeholder: {
    color: theme.palette.text.disabled,
  },

  errorMessage: {
    marginLeft: 14,
    lineHeight: 1.25,
    fontSize: 12,
    color: theme.palette.error.main,
  },

  checkDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  checkBox: {
    padding: theme.spacing(1),
  },
  checkTxt: {
    marginLeft: 4,
    lineHeight: 1.25,
    fontSize: 14,
  },
}));

//////////////////////////////////////// GRAPHQL ////////////////////////////////////////

const SAVE_INSURANCE = gql`
  mutation driver_saveInsurance($driverId: bigint!, $driverdetailInput: driverdetails_set_input!) {
    update_driverdetails(where: { driver_id: { _eq: $driverId } }, _set: $driverdetailInput) {
      affected_rows
      returning {
        driver_id
        insurance_provider
        insurance_policy_id
        insurance_coverage_amount
        insurance_rideshare_rider
        updated_at
      }
    }
  }
`;
