import React from 'react';
import { toast } from 'react-toastify';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import GlobalContext from '../../../GlobalContext';
import {
  useTheme,
  makeStyles,
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  MenuItem,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import gql from 'graphql-tag';

import { useTools } from '../../../hooks/useTools';
import useFormat from '../../../hooks/useFormat';
import useOnboarding from './useOnboarding';

import WorkflowBar from './WorkflowBar';

const log = false;

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function FormVehicleInfo({ driver = {}, flowGroup, flowSection, onClose, refetch }) {
  const theme = useTheme();
  const ctx = React.useContext(GlobalContext);
  const cls = useStyles();

  // Hook functions
  const { genListOfYears, genListOfColors, genListOfStates } = useTools();
  const { formatString } = useFormat();
  const { proceedToPrevWorkflow, proceedToNextWorkflow } = useOnboarding();

  const listOfYears = genListOfYears();
  const listOfColors = genListOfColors();
  const listOfStates = genListOfStates();

  // General form state
  const [editMode, setEditMode] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [validation, setValidation] = React.useState({});

  // Value form State
  const [color, setColor] = React.useState(``);
  const [licensePlate, setLicensePlate] = React.useState(``);
  const [licensePlateState, setLicensePlateState] = React.useState(`none`);
  const [make, setMake] = React.useState(``);
  const [model, setModel] = React.useState(``);
  const [vin, setVin] = React.useState(``);
  const [year, setYear] = React.useState(``);

  // Update state with driver fields (when driver changes)
  React.useEffect(() => {
    if (driver) {
      setColor(driver.vehicle_color || `none`);
      setLicensePlate(driver.vehicle_license_plate || ``);
      setLicensePlateState(driver.vehicle_license_plate_state || `none`);
      setMake(driver.vehicle_make || ``);
      setModel(driver.vehicle_model || ``);
      setVin(driver.vehicle_vin || ``);
      setYear(driver.vehicle_year || `none`);
    }
  }, [driver]);

  // Checks if all inputs are valid
  const handleValidateForm = () => {
    let valid = true;

    const newValidation = {
      color: color !== `none`,
      licensePlate: licensePlate ? true : false,
      licensePlateState: licensePlateState !== `none`,
      make: make ? true : false,
      model: model ? true : false,
      vin: vin && vin.length === 17 ? true : false,
      year: year !== `none`,
    };
    setValidation(newValidation);

    Object.keys(newValidation).forEach((key, i) => {
      const value = Object.values(newValidation)[i];
      if (value === false) {
        valid = false;
        setIsSaving(false);
      }
    });

    return valid;
  };

  // Mutation handler
  const saveVehicle = async () => {
    const variables = {
      driverId: driver.id,
      driverInput: {
        vehicle_color: color !== `none` ? color : null,
        vehicle_license_plate: licensePlate || null,
        vehicle_license_plate_state: licensePlateState !== `none` ? licensePlateState : null,
        vehicle_make: make || null,
        vehicle_model: model || null,
        vehicle_vin: vin || null,
        vehicle_year: year !== `none` ? year : null,
      },
    };

    log && console.log(`saveVehicle - Variables:`, variables);

    try {
      const res = await ctx.apolloClient.mutate({
        mutation: SAVE_VEHICLE,
        variables: variables,
      });

      if (getPropValue(res, `data.update_drivers.affected_rows`)) {
        if (refetch) refetch();
        return { success: true };
      }

      const errStr = `Failed to update information. Please contact support.`;
      console.error(errStr);
      return { success: false, error: errStr };
    } catch (err) {
      const errStr = `Failed to update information. Please contact support.`;
      console.error(`Error updating information:`, err);
      return { success: false, error: errStr };
    }
  };

  // Handle saving the form
  const handleSave = async (proceed = false) => {
    const res = await saveVehicle();
    if (res && res.success === true) {
      setIsSaving(false);
      toast.success(`Information saved!`, { autoClose: 2500 });
      if (proceed) {
        proceedToNextWorkflow(flowGroup, flowSection.id, onClose);
        return;
      }
      if (onClose) {
        onClose();
        return;
      }
    }
    if (res && res.error) {
      toast.error(res.error);
    }
  };

  // List of button actions in the workflow bar
  const actions = [
    {
      label: `Save`,
      icon: `save`,
      color: `secondary`,
      tip: `Save changes & return to the LaunchPad.`,
      handler: async () => {
        setIsSaving(true);
        const valid = handleValidateForm();
        if (valid) await handleSave();
        else toast.error(`Failed to save. Please check the form for errors.`);
      },
      hide: !editMode,
    },
    {
      label: `Save & Proceed`,
      icon: `double_arrow`,
      color: `primary`,
      tip: `Save changes & proceed to the next section.`,
      handler: async () => {
        setIsSaving(true);
        const valid = handleValidateForm();
        if (valid) await handleSave(true);
        else toast.error(`Failed to save. Please check the form for errors.`);
      },
      hide: !editMode,
    },
    {
      label: `Back`,
      icon: `arrow_back`,
      variant: `outlined`,
      color: `secondary`,
      tip: `Jump to the previous section.`,
      handler: () => proceedToPrevWorkflow(flowGroup, flowSection.id, onClose),
      hide: editMode,
    },
    {
      label: `Forward`,
      icon: `arrow_forward`,
      variant: `outlined`,
      color: `primary`,
      tip: `Jump to the next section.`,
      handler: () => proceedToNextWorkflow(flowGroup, flowSection.id, onClose),
      hide: editMode,
    },
  ];

  return (
    <>
      <WorkflowBar
        groupTitle={flowGroup.label}
        sectionTitle={flowSection.label}
        actions={actions}
        isSaving={isSaving}
        onClose={onClose}
        // editMode={editMode}
        // setEditMode={setEditMode}
      />

      <div className={cls.workflowPad} />

      <Spacer size='xxl' />

      <Container maxWidth='md'>
        <div className={cls.workflow}>
          {/* VEHICLE DESCRIPTION INFORMATION */}

          <Typography className={cls.title}>Vehicle Description</Typography>

          <Typography className={cls.subtitle}>Describe your vehicle to us.</Typography>

          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  Vehicle Make<span style={{ color: theme.palette.error.main }}> *</span>
                </Typography>
              </div>
              <TextField
                disabled={!editMode}
                required
                fullWidth
                placeholder='Enter vehicle make...'
                variant='outlined'
                size='small'
                value={make}
                onChange={e => setMake(e.target.value)}
                onBlur={() => setMake(formatString(make, { capEach: true }))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        drive_eta
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.make === false}
                helperText={validation.make === false ? `Vehicle make is invalid` : null}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  Vehicle Model<span style={{ color: theme.palette.error.main }}> *</span>
                </Typography>
              </div>
              <TextField
                disabled={!editMode}
                required
                fullWidth
                placeholder='Enter vehicle model...'
                variant='outlined'
                size='small'
                value={model}
                onChange={e => setModel(e.target.value)}
                onBlur={() => setModel(formatString(model, { capEach: true }))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        drive_eta
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.model === false}
                helperText={validation.model === false ? `Vehicle model is invalid` : null}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  Vehicle Year<span style={{ color: theme.palette.error.main }}> *</span>
                </Typography>
              </div>
              <TextField
                disabled={!editMode}
                required
                select
                fullWidth
                placeholder='Select vehicle year...'
                variant='outlined'
                size='small'
                value={year}
                onChange={e => setYear(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        no_crash
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.year === false}
                helperText={validation.year === false ? `Vehicle year is invalid` : null}
              >
                <MenuItem value='none'>
                  <div className={cls.placeholder}>Select vehicle year...</div>
                </MenuItem>
                {listOfYears && listOfYears.length
                  ? listOfYears.map(year => (
                      <MenuItem key={`vehicle-year-${year}`} value={year}>
                        {year}
                      </MenuItem>
                    ))
                  : null}
              </TextField>
            </Grid>

            <Grid item md={6} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  Vehicle Color<span style={{ color: theme.palette.error.main }}> *</span>
                </Typography>
              </div>

              <TextField
                disabled={!editMode}
                required
                select
                fullWidth
                placeholder='Select vehicle color...'
                variant='outlined'
                size='small'
                value={color}
                onChange={e => setColor(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        local_car_wash
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.color === false}
                helperText={validation.color === false ? `Vehicle color is invalid` : null}
              >
                <MenuItem value='none'>
                  <div className={cls.placeholder}>Select vehicle color...</div>
                </MenuItem>
                {listOfColors && listOfColors.length
                  ? listOfColors.map(color => (
                      <MenuItem key={`vehicle-color-${color}`} value={color}>
                        {color}
                      </MenuItem>
                    ))
                  : null}
              </TextField>
            </Grid>
          </Grid>

          <Spacer size='xxl' />

          {/* VEHICLE IDENTITY INFORMATION */}

          <Typography className={cls.title}>Vehicle Identity</Typography>

          <Typography className={cls.subtitle}>
            VIN is used to help us identify other general information about your vehicle.
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  Vehicle Identification Number (VIN)<span style={{ color: theme.palette.error.main }}> *</span>
                </Typography>
              </div>

              <TextField
                disabled={!editMode}
                required
                fullWidth
                placeholder='Enter VIN...'
                variant='outlined'
                size='small'
                value={vin}
                onChange={e => setVin(e.target.value, true)}
                onBlur={() => setVin(formatString(vin, { removeSpaces: true, capAll: true }))}
                inputProps={{ maxLength: 17 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        tag
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.vin === false}
                helperText={validation.vin === false ? `VIN is invalid` : null}
              />
            </Grid>
          </Grid>

          <Spacer size='xxl' />

          {/* LICENSE PLATE INFORMATION */}

          <Typography className={cls.title}>License Plate</Typography>

          <Typography className={cls.subtitle}>What is the license plate on the vehicle?</Typography>

          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  License Plate Number<span style={{ color: theme.palette.error.main }}> *</span>
                </Typography>
              </div>

              <TextField
                disabled={!editMode}
                required
                fullWidth
                placeholder='Enter license plate number...'
                variant='outlined'
                size='small'
                value={licensePlate}
                onChange={e => setLicensePlate(e.target.value)}
                onBlur={() => setLicensePlate(formatString(licensePlate, { capAll: true }))}
                inputProps={{ maxLength: 8 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        password
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.licensePlate === false}
                helperText={validation.licensePlate === false ? `License plate is invalid` : null}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  License Plate State<span style={{ color: theme.palette.error.main }}> *</span>
                </Typography>
              </div>

              <TextField
                disabled={!editMode}
                required
                select
                fullWidth
                placeholder='Select state...'
                variant='outlined'
                size='small'
                value={licensePlateState}
                onChange={e => setLicensePlateState(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        flag
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.licensePlateState === false}
                helperText={validation.licensePlateState === false ? `State is invalid` : null}
              >
                <MenuItem value='none'>
                  <div className={cls.placeholder}>Select state...</div>
                </MenuItem>
                {listOfStates && listOfStates.length
                  ? listOfStates.map(s => (
                      <MenuItem key={`vehicle-license-plate-state-${s.initials}`} value={s.initials}>
                        {s.initials} ({s.full})
                      </MenuItem>
                    ))
                  : null}
              </TextField>
            </Grid>
          </Grid>

          <Spacer size='xxl' />
        </div>
      </Container>

      <Spacer size='xl' />
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = makeStyles(theme => ({
  workflowPad: {
    width: '100%',
    height: 56,
  },

  workflow: {
    position: 'relative',
    width: '100%',
    // padding: theme.spacing(2),
    // borderRadius: theme.shape.paperRadius,
    // background: theme.palette.background.paper,
    // boxShadow: theme.shadow.medium,
  },

  title: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  subtitle: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1.25,
    fontSize: 14,
    // color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },

  label: {
    display: 'flex',
  },
  labelTxt: {
    verticalAlign: 'top',
    margin: '4px 0',
    lineHeight: 1,
    fontSize: 14,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      margin: '3px 0',
      fontSize: 12,
    },
  },
  labelHelp: {
    display: 'block',
    verticalAlign: 'top',
    marginLeft: 'auto',
    lineHeight: 1,
    fontSize: 20,
    color: theme.palette.text.disabled,
    '&:hover': {
      color: theme.palette.text.primary,
    },
    transition: '0.15s',
    cursor: 'pointer',
  },

  adornment: {
    verticalAlign: 'top',
    marginBottom: 6,
  },
  adornmentOutlined: {
    verticalAlign: 'top',
  },
  placeholder: {
    color: theme.palette.text.disabled,
  },
}));

//////////////////////////////////////// GRAPHQL ////////////////////////////////////////

const SAVE_VEHICLE = gql`
  mutation driver_saveVehicle($driverId: bigint!, $driverInput: drivers_set_input!) {
    update_drivers(where: { id: { _eq: $driverId } }, _set: $driverInput) {
      affected_rows
      returning {
        id
        vehicle_color
        vehicle_license_plate
        vehicle_license_plate_state
        vehicle_make
        vehicle_model
        vehicle_vin
        vehicle_year
        updated_at
      }
    }
  }
`;
