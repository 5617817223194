import React from 'react';
import { toast } from 'react-toastify';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import GlobalContext from '../../../GlobalContext';
import {
  useTheme,
  makeStyles,
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  MenuItem,
} from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import gql from 'graphql-tag';

import { useTools } from '../../../hooks/useTools';
import useFormat from '../../../hooks/useFormat';
import useOnboarding from './useOnboarding';

import WorkflowBar from './WorkflowBar';

const log = false;

//////////////////////////////////////// COMPONENT ////////////////////////////////////////

export default function FormLicenseInfo({ driver = {}, flowGroup, flowSection, onClose, refetch }) {
  const theme = useTheme();
  const ctx = React.useContext(GlobalContext);
  const cls = useStyles();

  // Hook functions
  const { genListOfStates } = useTools();
  const { formatString } = useFormat();
  const { proceedToPrevWorkflow, proceedToNextWorkflow } = useOnboarding();

  const listOfStates = genListOfStates();

  // General form state
  const [editMode, setEditMode] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [validation, setValidation] = React.useState({});

  // Value form State
  const [number, setNumber] = React.useState(``);
  // const [photo, setPhoto] = React.useState(``);
  const [state, setState] = React.useState(`none`);

  // Update state with driver fields (when driver changes)
  React.useEffect(() => {
    if (driver) {
      setNumber(driver.license_number || ``);
      // setPhoto(driver.license_photo_url || ``);
      setState(driver.license_state || `none`);
    }
  }, [driver]);

  // Checks if all inputs are valid
  const handleValidateForm = () => {
    let valid = true;

    const newValidation = {
      number: number ? true : false,
      state: state !== `none`,
      // photo: photo ? true : false,
    };
    setValidation(newValidation);

    Object.keys(newValidation).forEach((key, i) => {
      const value = Object.values(newValidation)[i];
      if (value === false) {
        valid = false;
        setIsSaving(false);
      }
    });

    return valid;
  };

  // Mutation handler
  const saveLicense = async () => {
    const variables = {
      driverId: driver.id,
      driverdetailInput: {
        license_number: number || null,
        license_state: state !== `none` ? state : null,
        // license_photo_url: photo || null,
      },
    };

    log && console.log(`saveLicense - Variables:`, variables);

    try {
      const res = await ctx.apolloClient.mutate({
        mutation: SAVE_LICENSE,
        variables: variables,
      });

      if (getPropValue(res, `data.update_driverdetails.affected_rows`)) {
        if (refetch) refetch();
        return { success: true };
      }

      const errStr = `Failed to update information. Please contact support.`;
      console.error(errStr);
      return { success: false, error: errStr };
    } catch (err) {
      const errStr = `Failed to update information. Please contact support.`;
      console.error(`Error updating information:`, err);
      return { success: false, error: errStr };
    }
  };

  // Handle saving the form
  const handleSave = async (proceed = false) => {
    const res = await saveLicense();
    if (res && res.success === true) {
      setIsSaving(false);
      toast.success(`Information saved!`, { autoClose: 2500 });
      if (proceed) {
        proceedToNextWorkflow(flowGroup, flowSection.id, onClose);
        return;
      }
      if (onClose) {
        onClose();
        return;
      }
    }
    if (res && res.error) {
      toast.error(res.error);
    }
  };

  // List of button actions in the workflow bar
  const actions = [
    {
      label: `Save`,
      icon: `save`,
      color: `secondary`,
      tip: `Save changes & return to the LaunchPad.`,
      handler: async () => {
        setIsSaving(true);
        const valid = handleValidateForm();
        if (valid) await handleSave();
        else toast.error(`Failed to save. Please check the form for errors.`);
      },
      hide: !editMode,
    },
    {
      label: `Save & Proceed`,
      icon: `double_arrow`,
      color: `primary`,
      tip: `Save changes & proceed to the next section.`,
      handler: async () => {
        setIsSaving(true);
        const valid = handleValidateForm();
        if (valid) await handleSave(true);
        else toast.error(`Failed to save. Please check the form for errors.`);
      },
      hide: !editMode,
    },
    {
      label: `Back`,
      icon: `arrow_back`,
      variant: `outlined`,
      color: `secondary`,
      tip: `Jump to the previous section.`,
      handler: () => proceedToPrevWorkflow(flowGroup, flowSection.id, onClose),
      hide: editMode,
    },
    {
      label: `Forward`,
      icon: `arrow_forward`,
      variant: `outlined`,
      color: `primary`,
      tip: `Jump to the next section.`,
      handler: () => proceedToNextWorkflow(flowGroup, flowSection.id, onClose),
      hide: editMode,
    },
  ];

  return (
    <>
      <WorkflowBar
        groupTitle={flowGroup.label}
        sectionTitle={flowSection.label}
        actions={actions}
        isSaving={isSaving}
        onClose={onClose}
        // editMode={editMode}
        // setEditMode={setEditMode}
      />

      <div className={cls.workflowPad} />

      <Spacer size='xxl' />

      <Container maxWidth='md'>
        <div className={cls.workflow}>
          {/* DRIVERS LICENSE INFORMATION */}

          <Typography className={cls.title}>Drivers License</Typography>

          <Typography className={cls.subtitle}>
            We require drivers license info for background checks and insurance purposes.
          </Typography>

          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  Drivers License Number<span style={{ color: theme.palette.error.main }}> *</span>
                </Typography>
              </div>

              <TextField
                disabled={!editMode}
                required
                fullWidth
                placeholder='Enter drivers license number...'
                variant='outlined'
                size='small'
                value={number}
                onChange={e => setNumber(e.target.value)}
                onBlur={() => setNumber(formatString(number, { removeSpaces: true, capAll: true }))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        tag
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.number === false}
                helperText={validation.number === false ? `Drivers license number is invalid` : null}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <div className={cls.label}>
                <Typography className={cls.labelTxt}>
                  Drivers License State<span style={{ color: theme.palette.error.main }}> *</span>
                </Typography>
              </div>

              <TextField
                disabled={!editMode}
                required
                select
                fullWidth
                placeholder='Select state...'
                variant='outlined'
                size='small'
                value={state}
                onChange={e => setState(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment className={cls.adornmentOutlined} position='start'>
                      <Icon color='disabled' fontSize='small'>
                        flag
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                error={validation.state === false}
                helperText={validation.state === false ? `State is invalid` : null}
              >
                <MenuItem value='none'>
                  <div className={cls.placeholder}>Select state...</div>
                </MenuItem>
                {listOfStates && listOfStates.length
                  ? listOfStates.map(s => (
                      <MenuItem key={`drivers-license-state-${s.initials}`} value={s.initials}>
                        {s.initials} ({s.full})
                      </MenuItem>
                    ))
                  : null}
              </TextField>
            </Grid>
          </Grid>

          {/* <Spacer size='xxl' /> */}

          {/* DRIVERS LICENSE PHOTO UPLOADER */}

          {/* <Typography className={cls.title}>Drivers License Photo Upload</Typography>

          <div className={cls.label}>
            <Typography className={cls.labelTxt}>Click Here To Upload<span style={{ color: theme.palette.error.main }}> *</span></Typography>
          </div>

          <Button component='label'>
            <Icon className={cls.btnIcon}>upload</Icon>
            Upload Drivers License Photo
            <input hidden type='file' name='img' accept='image/*' />
          </Button> */}

          <Spacer size='xxl' />
        </div>
      </Container>

      <Spacer size='xl' />
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////

const useStyles = makeStyles(theme => ({
  workflowPad: {
    width: '100%',
    height: 56,
  },

  workflow: {
    position: 'relative',
    width: '100%',
    // padding: theme.spacing(2),
    // borderRadius: theme.shape.paperRadius,
    // background: theme.palette.background.paper,
    // boxShadow: theme.shadow.medium,
  },

  title: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  subtitle: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1.25,
    fontSize: 14,
    // color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },

  label: {
    display: 'flex',
  },
  labelTxt: {
    verticalAlign: 'top',
    margin: '4px 0',
    lineHeight: 1,
    fontSize: 14,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      margin: '3px 0',
      fontSize: 12,
    },
  },
  labelHelp: {
    display: 'block',
    verticalAlign: 'top',
    marginLeft: 'auto',
    lineHeight: 1,
    fontSize: 20,
    color: theme.palette.text.disabled,
    '&:hover': {
      color: theme.palette.text.primary,
    },
    transition: '0.15s',
    cursor: 'pointer',
  },

  adornment: {
    verticalAlign: 'top',
    marginBottom: 6,
  },
  adornmentOutlined: {
    verticalAlign: 'top',
  },
  placeholder: {
    color: theme.palette.text.disabled,
  },

  btnIcon: {
    marginTop: -2,
    marginLeft: -4,
    marginRight: 8,
    fontSize: 16,
  },
}));

//////////////////////////////////////// GRAPHQL ////////////////////////////////////////

const SAVE_LICENSE = gql`
  mutation driver_saveLicense($driverId: bigint!, $driverdetailInput: driverdetails_set_input!) {
    update_driverdetails(where: { driver_id: { _eq: $driverId } }, _set: $driverdetailInput) {
      affected_rows
      returning {
        driver_id
        license_number
        license_state
        license_photo_url
        updated_at
      }
    }
  }
`;
