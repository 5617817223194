// DEPENDENCIES ---------------------------------------------------------------- //

import React from 'react';
import Script from 'react-load-script';
import axios from 'axios';

import { makeStyles, Typography, TextField, InputAdornment, Icon, CircularProgress } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import GlobalContext from '../../../GlobalContext';
import useGoogle from '../../../hooks/useGoogle';

// COMPONENT ---------------------------------------------------------------- //

export default function DriverLocationSelect({
  disabled,
  type = `driver-home`,
  placeholder,
  defaultLocationId,
  location,
  onLocationChange,
}) {
  const ctx = React.useContext(GlobalContext);
  const cls = useStyles();

  const googleService = useGoogle();

  const [input, setInput] = React.useState(``);
  const [suggestions, setSuggestions] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = React.useState(false);
  const [error, setError] = React.useState(``);

  /** Handles the location change event for the autocomplete input based on selected suggestion */
  const handleLocationChange = async suggestion => {
    // Build a new location object with the Google data provided
    const builtLocation = await handleBuildLocationFromSuggestion(suggestion);
    let locationOutput = { ...(builtLocation || suggestion) };

    // If the location has an id, add it to the location output
    if (defaultLocationId) locationOutput.id = defaultLocationId;

    // Set the suggestion
    if (onLocationChange) onLocationChange(locationOutput);
  };

  /** Handles the input change event for the autocomplete input */
  const handleInputChange = async (value, reason) => {
    setError(``);
    setInput(value);

    if (reason === `input` && value?.length >= 3) {
      await handleGoogleSearch(value);
    } else {
      setSuggestions([]);
    }
  };

  /** Handles the focus event for the autocomplete input */
  const handleFocusEnter = () => {
    setError(``);
  };

  /** Handles the blur event for the autocomplete input */
  const handleFocusExit = async () => {
    if (!location?.address && input?.length >= 3) {
      // Build the location from the address
      const builtLocation = await handleBuildLocationFromAddress(input);
      let locationOutput = { ...builtLocation };

      // If the location has an id, add it to the location output
      if (defaultLocationId) locationOutput.id = defaultLocationId;

      // Set the suggestion
      if (onLocationChange) onLocationChange(locationOutput);
    }
  };

  /** Handle Google suggestions */
  const handleGoogleSearch = async input => {
    if (isLoadingGoogle) return;

    setIsLoadingGoogle(true);

    await googleService?.textSearch({ query: input }, res => {
      if (res) {
        // Map Google suggestions to a DB location format
        const newSuggestions = res.map(s => ({
          address: s?.formatted_address,
          latitude: s?.geometry?.location?.lat(),
          longitude: s?.geometry?.location?.lng(),
          name: s?.name,
          place_id: s?.place_id,
        }));

        // Set the suggestions
        setSuggestions(newSuggestions);
      }
    });

    setIsLoadingGoogle(false);
  };

  /** Handle building a location from a Google suggestion */
  const handleBuildLocationFromSuggestion = async suggestion => {
    if (!suggestion) return;

    setIsLoading(true);

    // Get the user token from the store
    const userToken = ctx.token;

    // Build the location input and output objects
    let locationInput = {
      active: 1,
      address: suggestion?.address || null,
      latitude: suggestion?.latitude || null,
      longitude: suggestion?.longitude || null,
      name: suggestion?.name || null,
      place_id: suggestion?.place_id || null,
      type: type,
      createdat: `now()`,
      updatedat: `now()`,
    };
    let locationOutput = null;

    // Fill out the rest of the location from a netlify function
    try {
      const buildRes = await axios({
        method: `POST`,
        url: `/.netlify/functions/buildLocationFromSuggestion`,
        data: {
          locationInput: locationInput,
        },
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });

      if (buildRes?.data?.success) {
        locationOutput = buildRes?.data?.locationOutput;
      }
    } catch (err) {
      console.error(`Failed to build location:`, err);
      setError(`Failed to find location based on the suggestion provided! Please contact support.`);
    }

    // Set loading to false
    setIsLoading(false);

    // Return the location output
    return locationOutput;
  };

  /** Handle building a location from an address */
  const handleBuildLocationFromAddress = async address => {
    if (!address) return;

    setIsLoading(true);

    // Get the user token from the store
    const userToken = ctx.token;

    // Build the location input and output objects
    let locationInput = {
      active: 1,
      address: address || null,
      type: type,
      createdat: `now()`,
      updatedat: `now()`,
    };
    let locationOutput = null;

    // Fill out the rest of the location from a netlify function
    try {
      const buildRes = await axios({
        method: `POST`,
        url: `/.netlify/functions/buildLocationFromAddress`,
        data: {
          locationInput: locationInput,
        },
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });

      if (buildRes?.data?.success) {
        locationOutput = buildRes?.data?.locationOutput;
      }
    } catch (err) {
      console.error(`Failed to build location:`, err);
      setError(
        `Failed to find location based on the address provided! Please provide a full address or select from the suggestions in the list. If this problem persists, please contact support.`
      );
    }

    // Set loading to false
    setIsLoading(false);

    // Return the location output
    return locationOutput;
  };

  return (
    <>
      <Script url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GPLCS}&libraries=places`} />

      <Autocomplete
        fullWidth
        freeSolo
        disabled={disabled}
        loading={isLoadingGoogle}
        loadingText='Searching...'
        noOptionsText='No suggestions found'
        getOptionLabel={option => option?.name || option?.address || ``}
        getOptionSelected={(option, value) => option.value === value.value}
        options={suggestions}
        filterOptions={createFilterOptions({ stringify: option => `${option.name} ${option.address}` })}
        renderOption={option => (
          <div className={cls.option}>
            <Icon color='disabled' fontSize='small'>
              location_on
            </Icon>

            <div className={cls.optionBlock}>
              <Typography className={cls.optionNameTxt}>{option?.name}</Typography>
              <Typography className={cls.optionAddressTxt}>{option?.address}</Typography>
            </div>
          </div>
        )}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            required
            error={error?.length ? true : false}
            helperText={error || location?.address}
            placeholder={placeholder || `Find address...`}
            variant='outlined'
            size='small'
            InputProps={{
              ...params?.InputProps,
              onKeyDown: e => {
                if (e.key === `Enter`) {
                  e.preventDefault();
                  e.stopPropagation();
                  handleFocusExit();
                }
              },
              startAdornment: (
                <InputAdornment className={cls.adornment} position='start'>
                  {isLoading || isLoadingGoogle ? (
                    <CircularProgress size={20} thickness={4} />
                  ) : (
                    <Icon color='disabled' fontSize='small'>
                      {type === `driver-home` ? `home` : `mail`}
                    </Icon>
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
        value={location}
        onChange={(event, value) => handleLocationChange(value)}
        onInputChange={(event, value, reason) => handleInputChange(value, reason)}
        onFocus={() => handleFocusEnter()}
        onBlur={() => handleFocusExit()}
        style={isLoading ? { userSelect: `none`, pointerEvents: `none` } : undefined}
      />
    </>
  );
}

// STYLES ---------------------------------------------------------------- //

const useStyles = makeStyles(theme => ({
  adornment: {
    verticalAlign: 'top',
    marginLeft: 8,
    marginRight: 4,
  },

  option: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  optionBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  optionNameTxt: {
    fontSize: 14,
    fontWeight: 500,
  },
  optionAddressTxt: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
}));
